// src/Terms.js
import React from 'react';

function Terms() {
  return (
    <div class="page">
        <div class="header">
            <div class="container">
                <p class="title">Terms and Conditions for NX Scanner</p>
            </div>
        </div>
        <div class="translations-content-container">
            <div class="container">
                <div class="tab-content translations-content-item en visible" id="en">
                    <h1>Terms and Conditions</h1>
                    <h3>1. Introduction</h3>
                    <p>Welcome to NX Scanner! These Terms and Conditions ("Terms") govern your use of the mobile application, NX Scanner (the "App"), developed and operated by [Your Company Name] ("We" or "Us").</p>
                    <h3>2. Acceptance of Terms</h3>
                    <p>By downloading, installing, or using the App, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access or use the App.</p>
                    <h3>3. User Accounts</h3>
                    <h4>3.1 Account Creation</h4>
                    <p>To access NX Scanner's full functionality, you must create an account using your phone number.</p>
                    <p>Verification via a One-Time Password (OTP) is mandatory to complete account creation.</p>
                    <h4>3.2 Profile Completion</h4>
                    <p>After verification, you'll need to complete your profile by providing your email address and name.</p>
                    <p>You can also choose to upload an image for your profile (optional).</p>
                    <h3>4. Service Description</h3>
                    <p>NX Scanner allows you to:</p>
                    <ul>
                        <li>Scan QR codes</li>
                        <li>Earn money for scanning QR codes (details on earning mechanics will be provided separately)</li>
                        <li>Withdraw earned money to your bank account by adding account details within the App</li>
                    </ul>
                    <h4>Important Note on Withdrawals:</h4>
                    <ul>
                        <li>When you request a withdrawal, the request is sent to our administrative team for review.</li>
                        <li>Upon approval, we will transfer the earned money to your bank account details provided within the App.</li>
                    </ul>
                
                    <h3>5. User Conduct</h3>
                    <p>You agree to use NX Scanner in a lawful and ethical manner, adhering to all applicable laws and regulations in Gujarat, India. You are prohibited from using the App for any purpose that:</p>
                    <ul>
                        <li>Violates any laws or regulations.</li>
                        <li>Exploits, harms, or disrupts the App or other users.</li>
                        <li>Transmits or stores illegal, harassing, defamatory, obscene, or privacy-invading content.</li>
                        <li>Interferes with the App's servers or networks.</li>
                    </ul>
                    <h3>6. Information Collection and Use</h3>
                    <p>We collect certain information when you use the App, including:</p>
                    <ul>
                        <li>Phone number (for account creation and verification)</li>
                        <li>Email address (optional, for profile completion)</li>
                        <li>Name (optional, for profile completion)</li>
                        <li>Profile image (optional)</li>
                        <li>Usage data (e.g., device information, scanning activity)</li>
                    </ul>
                    <p>We use this information to:</p>
                    <ul>
                        <li>Provide and improve the App</li>
                        <li>Manage your account</li>
                        <li>Contact you (e.g., for account-related matters, updates, or offers)</li>
                        <li>Analyze user behavior to enhance the App</li>
                    </ul>
                    <p>For more details on information collection and use, please refer to our Privacy Policy (link to Privacy Policy here).</p>
                    <h3>7. Intellectual Property</h3>
                    <p>NX Scanner and its content (text, graphics, logos, images, software, etc.) are the property of NX or its licensors. These are protected by copyright, trademark, and other intellectual property laws. You may not modify, reproduce, distribute, create derivative works of, or commercially exploit any content from the App without our prior written consent.</p>
                    <h3>8. Disclaimer of Warranties</h3>
                    <p>NX Scanner is provided "as is" and "as available" without warranties of any kind, express or implied. We do not warrant that the App will be uninterrupted, secure, or error-free.</p>
                    <h3>9. Limitation of Liability</h3>
                    <p>We shall not be liable for any damages arising from or related to your use of the App, including direct, indirect, incidental, consequential, special, punitive, or exemplary damages.</p>
                    <h3>10. Termination</h3>
                    <p>We may terminate your access to the App

 for any reason, at any time, without notice. This includes violations of these Terms or inactivity on your account.</p>
                    <h3>11. Governing Law and Dispute Resolution</h3>
                    <p>These Terms shall be governed by and construed in accordance with the laws of Gujarat, India, without regard to its conflict of law provisions. Any dispute arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in Gujarat, India.</p>
                    <h3>12. Entire Agreement</h3>
                    <p>These Terms constitute the entire agreement between you and us regarding your use of the App.</p>
                    <h3>13. Contact Us</h3>
                    <p>If you have any questions about these Terms, please contact us at annexchemicals7@gmail.com.</p>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Terms;
