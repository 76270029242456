// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Routes } from 'react-router-dom';
import './App.css';
import Terms from './Terms';
import Privacy from './Privacy';

function App() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setMessage(`Thank you for subscribing, ${email}!`);
      setEmail('');
    }
  };

  return (
    <Router>
      <div className="">
        <Routes>
          <Route exact path="/" element={<div className="container" style={{width:"100vw",height:"100vh",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
              <h1>Coming Soon</h1>
              <p>Our website is under construction. We'll be here soon with our new awesome site.</p>
              
              {/* <div className="links">
                <Link to="/terms">Terms and Conditions</Link>
                <Link to="/privacy">Privacy Policy</Link>
              </div> */}
            </div>}>
            
          </Route>
          <Route path="/terms" element={<Terms />}>
            
          </Route>
          <Route path="/privacy" element={<Privacy />}>
      
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
